<template>
  <div class="home-section-one">
    <div class="py-5 py-md-7 pt-lg-9 pb-lg-10">
    <div id="whySellAnchor" class="mx-auto col-12 col-sm-10 text-center pb-4 pb-lg-6">
      <h2 class="sell-title">
        {{ $t("Sell.Content.WhySellWithPash") }}
        <!-- Why Sell with Pash? -->
      </h2>
      <div class="d-inline-block sell-subtitle" style="vertical-align: top">
        <h5>
          {{ $t("Sell.Content.AnswerWhyPash") }}
          <span class="text-primary">{{ $t("Sell.Content.AnswerWhyPash2") }}</span>
          {{ $t("Sell.Content.AnswerWhyPash3") }}
          <!-- Because of the many incredible benefits that we offer to our sellers. -->
        </h5>
      </div>
      <!-- <div class="d-inline-block">
        <b-img :src="arrow" class="d-none d-sm-block mt-3"></b-img>
      </div> -->
    </div>
    <div class="container">
      <div class="row">
        <div class="d-none d-lg-block col">
          <div class="fixed-background rounded">
            <div class="fixed-wrap fixed-bg-image loptop-typing rounded"></div>
          </div>
        </div>
        <div class="advantage mx-auto col-12 col-sm-10 col-lg-7 col-xl-6-custom col hover-blocks">
          <div
            class="benefit-box text-center text-lg-left p-3 p-md-4"
            :class="{ benefits_box1: selectedBox === 1 }"
            @mouseover="selectedBox = 1"
          >
            <h3 class="why-card-heading">
              {{ $t("Sell.Content.AccessNewCustomers") }}
              <!-- Access new customers -->
            </h3>
            <p>
              {{ $t("Sell.Content.AccessNewCustomersDescription") }}
              <!-- Your vehicles will be seen by a great number of potential buyers abroad. -->
            </p>
          </div>
          <div
            class="benefit-box text-center text-lg-left p-3 p-md-4"
            :class="{ benefits_box2: selectedBox === 2 }"
            @mouseover="selectedBox = 2"
          >
            <h3 class="why-card-heading">
              {{ $t("Sell.Content.Secure") }}
              <!-- 100% secure -->
            </h3>
            <p>
              {{ $t("Sell.Content.SecureDescription") }}
              <!-- No risk of scam by unscrupulous buyers. You deal only with our company throughout the process. -->
            </p>
          </div>
          <div
            class="benefit-box text-center text-lg-left p-3 p-md-4"
            :class="{ benefits_box3: selectedBox === 3 }"
            @mouseover="selectedBox = 3"
          >
            <h3 class="why-card-heading">
              {{ $t("Sell.Content.NoTimeWasted") }}
              <!-- No time wasted -->
            </h3>
            <p>
              {{ $t("Sell.Content.NoTimeWastedDescription") }}
              <!-- We conduct only one inspection and test drive for each vehicle, whatever the number of buyers interested. -->
            </p>
          </div>
          <div
            class="benefit-box text-center text-lg-left p-3 p-md-4"
            :class="{ benefits_box4: selectedBox === 4 }"
            @mouseover="selectedBox = 4"
          >
            <h3 class="why-card-heading">
              {{ $t("Sell.Content.GetPaidFast") }}
              <!-- Get paid fast -->
            </h3>
            <p>
              {{ $t("Sell.Content.GetPaidFastDescription") }}
              <!-- Once a buyer confirms the purchase of your vehicle, we pick it up and pay you immediately. -->
            </p>
          </div>
          <div
            class="benefit-box text-center text-lg-left p-3 p-md-4"
            :class="{ benefits_box5: selectedBox === 5 }"
            @mouseover="selectedBox = 5"
          >
            <h3 class="why-card-heading">
              {{ $t("Sell.Content.NoExtraWork") }}
              <!-- No extra work -->
            </h3>
            <p>
              {{ $t("Sell.Content.NoExtraWorkDescription") }}
              <!-- You do not have to worry about regulation, paperwork and shipping. We handle everything. -->
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
    <div class="step-background py-5 py-md-7 pt-lg-9 pb-lg-10">
      <p><a id="howItWorkAnchor" /></p>
      <div class="col-12 col-sm-10 col-lg-7 m-auto">
        <b-container class="mx-auto px-0">
          <div class="text-center text-light">
            <h2 class="sell-title">
              {{ $t("Sell.Content.HowItWorks") }}
              <!-- How it Works -->
            </h2>
            <h6 class="sell-subtitle">
              {{ $t("Sell.Content.HowItWorksAnswer") }}
              <!-- You do only the first two steps below. We take care of the rest. -->
            </h6>
          </div>
          <dl>
            <dt class="h4 font-weight-normal mr-3 d-none d-lg-block two-step">
              {{ $t("Sell.Content.HowItWorksDesc1") }}
            </dt>
            <!-- <Stepbox image="~@/assets/img/car-step-1.svg" :text='[$t("Sell.Content.Step1"),$t("Sell.Content.Step1Description")]'/> -->
            <dd class="step-box py-3 px-6 py-md-6 px-md-10">
              <div class="step-box-img d-none d-md-block">
                <img src="@/assets/img/car-step-1.svg" alt="step_1" class="img-fluid" />
              </div>
              <div class="step-box-text">
                <p class="text-primary">{{ $t("Sell.Content.Step") }} 1</p>
                <h5>
                  {{ $t("Sell.Content.Step1") }}
                  <!-- #Add your vehicle for Sale# -->
                </h5>
                <p>
                  {{ $t("Sell.Content.Step1Description") }}
                  <!-- Provide accurate answers to questions about the vehicle and upload great pictures. -->
                </p>
              </div>
            </dd>
            <dd class="step-box py-3 px-6 py-md-6 px-md-10">
              <div class="step-box-img d-none d-md-block">
                <img src="@/assets/img/tag-step2.svg" alt="step_2" class="img-fluid" />
              </div>
              <div class="step-box-text">
                <p class="text-primary">{{ $t("Sell.Content.Step") }} 2</p>
                <h5>
                  {{ $t("Sell.Content.Step2") }}
                  <!-- Set fixed prices -->
                </h5>
                <p>
                  {{ $t("Sell.Content.Step2Description") }}
                  <!-- We value no huggling over prices, so the prices you set are the prices at which the vehicles are sold. -->
                </p>
              </div>
            </dd>
            <dt class="h4 font-weight-normal ml-5 d-none d-lg-block">
              {{ $t("Sell.Content.HowItWorksDesc2") }}
            </dt>
            <dd class="step-box py-3 px-6 py-md-6 px-md-10 dark-primary">
              <div class="step-box-img d-none d-md-block">
                <img src="@/assets/img/wheel-step3.svg" alt="step_3" class="img-fluid" />
              </div>
              <div class="step-box-text">
                <p class="text-warning">{{ $t("Sell.Content.Step") }} 3</p>
                <h5 class="text-white">
                  {{ $t("Sell.Content.Step3") }}
                  <!-- Inspection and test drive -->
                </h5>
                <p class="text-white">
                  {{ $t("Sell.Content.Step3Description") }}
                  <!-- Once a buyer is interested, we conduct inspection and test drive to verify vehicle information and condition. -->
                </p>
              </div>
            </dd>
            <dd class="step-box py-3 px-6 py-md-6 px-md-10 dark-primary">
              <div class="step-box-img d-none d-md-block">
                <img
                  src="@/assets/img/handout-step4.svg"
                  alt="step_4"
                  class="img-fluid"
                />
              </div>
              <div class="step-box-text">
                <p class="text-warning">{{ $t("Sell.Content.Step") }} 4</p>
                <h5 class="text-white">
                  {{ $t("Sell.Content.Step4") }}
                  <!-- Vehicle pick up and payment -->
                </h5>
                <p class="text-white">
                  {{ $t("Sell.Content.Step4Description") }}
                  <!-- If the inspection is satisfactory and the buyer confirms the purchase, we pick up the vehicle and pay you immediately. -->
                </p>
              </div>
            </dd>
            <dd class="step-box py-3 px-6 py-md-6 px-md-10 dark-primary">
              <div class="step-box-img d-none d-md-block">
                <img src="@/assets/img/send-step5.svg" alt="step_5" class="img-fluid" />
              </div>
              <div class="step-box-text">
                <p class="text-warning">{{ $t("Sell.Content.Step") }} 5</p>
                <h5 class="text-white">
                  {{ $t("Sell.Content.Step5") }}
                  <!-- Paperwork and shipping -->
                </h5>
                <p class="text-white">
                  {{ $t("Sell.Content.Step5Description") }}
                  <!-- We complete the necessary paperwork and handle the shipping and delivery of the vehicle to the buyer. -->
                </p>
              </div>
            </dd>
          </dl>
        </b-container>
      </div>
    </div>
    <div class="container world-map text-center" style="background-size:contain;background-position: center;">
      <h2 class="mb-5">
          {{ $t("Sell.Content.StartSelling") }}
          <!-- Start selling cars internationally today! -->
        </h2>
        <div class="d-flex flex-column flex-sm-row justify-content-center gap-2 col-12 py-0">
          <a
            href="/listingOption"
            type="button"
            class="btn btn-primary btn-lg rounded-pill px-10"
          >
            {{ $t("Sell.Content.SellMyCar") }}
            <!-- Sell My Car --> </a
          ><a
            type="button"
            class="btn btn-outline-primary btn-lg rounded-pill px-10"
            href="/contact/contact"
            >{{ $t("Sell.Content.ContactUs") }}
            <!-- Contact us -->
          </a>
        </div>
    </div>
  </div>
</template>

<script>
import Stepbox from './subcomponent/Stepbox.vue';

export default {
  components: { Stepbox },
  props: {
    text: {
      type: Array,
    },
  },
  data() {
    return {
      selectedBox: 1,
      arrow: require("@/assets/img/arrow_down.png"),
    };
  },
  methods: {
    showText(key) {
      let t;
      if (this.text) {
        this.text.forEach((element) => {
          if (element.Key === key) {
            t = element.Value;
          }
        });
      }
      return t;
    },
  },
};
</script>

<style lang="scss">
.why-card-heading {
  font-size: large!important;;
}

.world-map {
  padding-top: 40px!important;
  padding-bottom: 40px!important;
  @media screen and (min-width:992px) {
    padding-top: 60px!important;
    padding-bottom: 60px!important;
  }
}

.home-section-one {
  position: relative;
  z-index: 2;

  .benefits_box1 {
    box-shadow: 0 0 1rem 0.5rem $gray-200;
    border-color: $light-blue !important;
    background: white;
  }

  .benefits_box1 h3 {
    color: #2277ef;
  }

  .benefits_box2 {
    border-color: $light-blue !important;
    box-shadow: 0 0 1rem 0.5rem $gray-200;
    background: white;
  }

  .benefits_box2 h3 {
    color: #2277ef!important;
  }

  .benefits_box3 {
    border-color: $light-blue !important;
    box-shadow: 0 0 1rem 0.5rem $gray-200;
    background: white;
  }

  .benefits_box3 h3 {
    color: #2277ef!important;
  }

  .benefits_box4 {
    border-color: $light-blue !important;
    box-shadow: 0 0 1rem 0.5rem $gray-200;
    background: white;
  }

  .benefits_box4 h3 {
    color: #2277ef!important;
  }

  .benefits_box5 {
    border-color: $light-blue !important;
    box-shadow: 0 0 1rem 0.5rem $gray-200;
    background: white;
  }

  .benefits_box5 h3 {
    color: #2277ef!important;
  }

  .sell-subtitle {
    font-size: 20px;
    > h5 {
      font-weight: 400;
    }
  }

  @media (max-width: 575px) {
    .fixed-background {
      padding-top: 61px;
    }
    .row h5 {
      padding: 0;
    }
    .sell-subtitle {
      font-size: 18px !important;
    }
  }

  .benefit-box {
    border-radius: 4px;
    /* min-height: 156px; */
    border-top: 8px solid #dbdbdb;
    transition: 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;

    &:not(:last-of-type) {
      margin-bottom: 24px;
    }

    p {
      color: #363636;
      font-size: 1rem;
      line-height: 24px;
    }

    h4 {
      margin-bottom: 20px;
      font-weight: 600;
      font-size: 24px;
    }

    @media screen and (min-width:1264px) {
      border-top: 0;
      border-left: 8px solid #dbdbdb;
    }
  }

  .step-background {
    .sell-title {
      color: #eff1f3;
    }

    .sell-subtitle {
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 48px;
      @media (max-width: 575px) {
        margin-bottom: 32px;
      }
    }
  }

  .step-box {
    background: #cce1f3;
    background-color: rgb(204, 225, 243);
    display: flex;
    margin-bottom: 16px;
    align-items: center;
    @media (max-width: 575px) {
      flex-direction: column;
      padding: 24px 24px;
    }

    &.dark-primary {
      background: $light-blue;
    }

    &-img {
      width: 64px;
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 40px;
      @media (max-width: 575px) {
        margin-right: 0;
        margin-bottom: 16px;
      }
    }
    
    &-text {
      width: 100%;
      @media (max-width: 575px) {
        text-align: center;
      }
    }

    .text-primary {
      font-weight: 500;
      margin-bottom: 5px;
    }


    p {
      color: #363636;
    }
  }

  .start-selling {
    margin-bottom: 80px;
    margin-top: 30px;
    padding-top: 60px;
    @media (max-width: 575px) {
      margin-bottom: 0;
    }

    h1 {
      font-size: 40px !important;
      @media (max-width: 575px) {
        font-size: 26px !important;
      }
    }

    &-buttons {
      margin-top: 40px;
      display: flex;
      justify-content: center;
      @media (max-width: 575px) {
        flex-direction: column;
      }

      .btn {
        min-width: 180px;
        padding: 0 16px;
        height: 55px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: 600;

        &.sell-mycar {
          margin-right: 32px;
          @media (max-width: 575px) {
            margin-right: 0;
            margin-bottom: 16px;
          }
        }

        &.btn-outline-primary {
          &:hover {
            color: white !important;
          }
        }
      }
    }
  }
}
</style>
<style scoped lang="scss">
.col-xl-6-custom {
  @media screen and (min-width:1264px) {
    flex: 0 0 50%!important;
    max-width: 50%!important;
  }
}
</style>