<template>
  <dd class="step-box">
              <div class="step-box-img d-none d-md-block">
                <img :src="images[key]" alt="step_1" class="img-fluid" />
              </div>
              <div class="step-box-text">
                <p class="text-primary">{{ $t("Sell.Content.Step") }} 1</p>
                <h5>
                  {{ text[0] }}
                  <!-- #Add your vehicle for Sale# -->
                </h5>
                <p>
                  {{ text[1] }}
                  <!-- Provide accurate answers to questions about the vehicle and upload great pictures. -->
                </p>
              </div>
            </dd>
</template>

<script>
export default {
    name: "Stepbox",
    props: {
        image: {
            type: String,
            required:true
        }, 
        text: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            images: [
                '@/assets/img/car-step-1.svg',
                '@/assets/img/car-step-2.svg',
                '@/assets/img/car-step-3.svg',
                '@/assets/img/car-step-4.svg',
                '@/assets/img/car-step-5.svg'
            ]
        }
    }
}
</script>

<style>

</style>